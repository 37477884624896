<ih-admin-base
  editorTitle="Subscriptions"
  editorIconPath="M5,6H23V18H5V6M14,9A3,3 0 0,1 17,12A3,3 0 0,1 14,15A3,3 0 0,1 11,12A3,3 0 0,1 14,9M9,8A2,2 0 0,1 7,10V14A2,2 0 0,1 9,16H19A2,2 0 0,1 21,14V10A2,2 0 0,1 19,8H9M1,10H3V20H19V22H1V10Z"
>
  <span button-controls>
    <button mat-button class="export-button" (click)="exportSubscriptions()">Export</button>
  </span>
  <ih-filter-chips ihFinancialFilterChips (filterChanged)="filterChanged($event)"></ih-filter-chips>
  @if (loading()) {
    <div class="progress-bar-container">
      <mat-progress-bar class="progress-bar" mode="query"></mat-progress-bar>
    </div>
  }
  <div class="subscription-list-container mat-elevation-z2">
    <table class="subscription-list-table" mat-table [dataSource]="items()" matSort>
      <!-- Member Column -->
      <ng-container matColumnDef="member">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Member</th>
        <td mat-cell *matCellDef="let row">
          @if (row.member) {
            <ih-author-info [author]="row.member">
              <div class="author-info-container">
                <div class="author">{{ row.member.firstName }} {{ row.member.lastName }}</div>
              </div>
            </ih-author-info>
          } @else {
            <div class="author-info-container">
              <div class="author">Unknown</div>
            </div>
          }
        </td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let row">
          <span class="amount-value">{{ row.amount.value | currency: row.amount.currency }}</span>
        </td>
      </ng-container>

      <!-- Total Column -->
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
        <td mat-cell *matCellDef="let row">
          <span class="amount-value">{{ row.amount.value | currency: row.amount.currency }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let row">
          {{ row.status }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let row">{{ row.createdAt }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No subscriptions matching the filter</td>
      </tr>
    </table>

    <mat-paginator class="paginator" [pageSizeOptions]="[50, 100]" aria-label="Select page of payments"></mat-paginator>
  </div>
</ih-admin-base>
