import { AsyncPipe, CurrencyPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  ViewChild,
  ViewEncapsulation,
  inject,
  signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { AdminBaseComponent } from '@ih/admin-base';
import { AuthorInfoComponent } from '@ih/author-info';
import { FilterChipsComponent, FinancialFilterChipsDirective } from '@ih/filter-chips';
import {
  ListFilterItem,
  ListFilterItemValue,
  SearchResponse,
  Subscription,
  SubscriptionListItem
} from '@ih/interfaces';
import { LazySnackBarService, TimeService } from '@ih/services';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, Observable, merge, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'ih-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    CurrencyPipe,
    NgClass,
    NgFor,
    NgIf,
    RouterLink,

    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,

    AdminBaseComponent,
    AuthorInfoComponent,
    FilterChipsComponent,
    FinancialFilterChipsDirective,

    InfiniteScrollModule
  ]
})
export class SubscriptionListComponent implements AfterViewInit {
  @HostBinding('class.ih-subscription-list') hostClass = true;

  private http = inject(HttpClient);
  private snackbar = inject(LazySnackBarService);
  private time = inject(TimeService);
  private destroyRef = inject(DestroyRef);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  loading = signal(false);

  displayedColumns = ['member', 'amount', 'total', 'status', 'createdAt'];
  items = signal<SubscriptionListItem[]>([]);

  private filters$ = new BehaviorSubject<ListFilterItem[]>([]);

  ngAfterViewInit(): void {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.filters$)
      .pipe(
        withLatestFrom(this.filters$),
        switchMap(([, filters]) => {
          this.loading.set(true);

          return this.getSubscriptions(
            filters,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize
          ).pipe(catchError(() => of(null)));
        }),
        tap({
          next: (resp) => {
            this.loading.set(false);
            if (!resp?.results) {
              return;
            }

            const paymentItems = resp.results.map((item) => {
              return {
                id: item.campaignUserChargeSubscriptionId,
                createdAt: item.createdAt,
                member: item.member,
                amount: {
                  value: item.amount,
                  currency: item.currency.code
                },
                status: item.active ? 'Active' : 'Inactive'
              } as SubscriptionListItem;
            });

            this.items.set(paymentItems);

            this.paginator.length = resp.hits;
          },
          error: () => {
            this.loading.set(false);
          }
        }),
        catchError((err, caught) => {
          this.snackbar.open('Error loading posts', 'TRY AGAIN').then((ref) => ref.onAction().subscribe(() => caught));

          throw err;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  getSubscriptions(
    filters: ListFilterItem[],
    sort: string,
    order: SortDirection,
    page: number,
    pageSize: number
  ): Observable<SearchResponse<Subscription>> {
    const params: { [key: string]: string } = {
      sort,
      order,
      page: page.toString(),
      pageSize: pageSize.toString()
    };
    filters.forEach((filter) => {
      params[filter.queryParam] = filter.multiple
        ? JSON.stringify((filter.query as ListFilterItemValue[]).map((v) => v.value))
        : ((filter.query as ListFilterItemValue).value as string);
    });

    return this.http
      .get<SearchResponse<Subscription<string>>>('/api/financial/subscriptions', {
        params
      })
      .pipe(
        map((resp) => ({
          ...resp,
          results: resp.results.map((item) => ({ ...item, createdAt: new Date(item.createdAt) }))
        }))
      );
  }

  exportSubscriptions(): void {
    window.open(
      '/api/financial/subscriptions/export?start=&end=' +
        '&source=' +
        '&description=&tz=' +
        this.time.getBrowserTimeZone()
    );
  }

  filterChanged(filters: ListFilterItem[]): void {
    this.sort.active = 'createdAt';
    this.sort.direction = 'desc';
    this.paginator.pageIndex = 0;

    this.filters$.next(filters);
  }
}
